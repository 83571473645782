.label {
	--background-color: transparent;
	--border-color: transparent;
	--border-radius: var(--radius-label);
	--border-style: none;
	--border-width: 0;
	--color: var(--label-color-text);
	--font-family: var(--label-typography-family);
	--font-size: var(--label-typography-size);
	--font-weight: var(--label-typography-weight);
	--letter-spacing: var(--label-typography-letter-spacing);

	align-items: center;
	background-color: var(--background-color);
	border-color: var(--border-color);
	border-radius: var(--border-radius);
	border-style: var(--border-style);
	border-width: var(--border-width);
	color: var(--color);
	display: inline-flex;
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	gap: var(--spacing-4xs);
	justify-content: center;
	letter-spacing: var(--letter-spacing);
	line-height: 1;
	padding: var(--spacing-3xs);
	text-wrap: nowrap;

	& svg {
		height: 1.25rem;
		width: 1.25rem;
	}

	& .media {
		height: 2.25rem;
		width: auto;
	}

	&:focus {
		text-decoration: none;
	}

	&.isDefault {
		padding: 0;
	}

	&.filled {
		background-color: var(--label-color-filled-background);
		border-color: var(--label-color-filled-border-color);
		border-style: var(--label-color-filled-border-style);
		border-width: var(--label-color-filled-border-width);
		color: var(--label-color-filled-text);
		text-decoration: none;

		&:hover {
			background-color: var(--label-color-filled-hover-background);
			border-color: var(--label-color-filled-hover-border-color);
			color: var(--label-color-filled-hover-text);
		}

		&:active {
			background-color: var(--label-color-filled-active-background);
			border-color: var(--label-color-filled-active-border-color);
			color: var(--label-color-filled-active-text);
		}

		&:focus {
			background-color: var(--label-color-filled-focus-background);
			border-color: var(--label-color-filled-focus-border-color);
			color: var(--label-color-filled-focus-text);
			outline-color: var(--label-color-filled-focus-outline-color);
			outline-style: var(--label-color-filled-focus-outline-style);
			outline-width: var(--label-color-filled-focus-outline-width);
		}
	}

	&.outlined {
		background-color: var(--label-color-outlined-background);
		border-color: var(--label-color-outlined-border-color);
		border-style: var(--label-color-outlined-border-style);
		border-width: var(--label-color-outlined-border-width);
		color: var(--label-color-outlined-text);

		&:hover {
			background-color: var(--label-color-outlined-hover-background);
			border-color: var(--label-color-outlined-hover-border-color);
			color: var(--label-color-outlined-hover-text);
		}

		&:active {
			background-color: var(--label-color-outlined-active-background);
			border-color: var(--label-color-outlined-active-border-color);
			color: var(--label-color-outlined-active-text);
		}

		&:focus {
			background-color: var(--label-color-outlined-focus-background);
			border-color: var(--label-color-outlined-focus-border-color);
			color: var(--label-color-outlined-focus-text);
			outline-color: var(--label-color-outlined-focus-outline-color);
			outline-style: var(--label-color-outlined-focus-outline-style);
			outline-width: var(--label-color-outlined-focus-outline-width);
		}
	}

	&.informational {
		background-color: var(--label-color-informational-background);
		color: var(--label-color-informational-text);
	}

	&.recipe {
		background-color: var(--label-color-recipe-background);
		color: var(--label-color-recipe-text);
	}
}

@container style(--is-dark-mode: 1) {

	.label {
		--color: var(--label-color-dark-text);

		&.informational {
			background-color: var(--label-color-informational-dark-background);
			color: var(--label-color-informational-dark-text);
		}

		&.recipe {
			background-color: var(--label-color-recipe-dark-background);
			color: var(--label-color-recipe-dark-text);
		}

		&.outlined {
			background-color: var(--label-color-outlined-dark-background);
			border-color: var(--label-color-outlined-dark-border-color);
			border-style: var(--label-color-outlined-dark-border-style);
			border-width: var(--label-color-outlined-dark-border-width);
			color: var(--label-color-outlined-dark-text);

			&:hover {
				background-color: var(--label-color-outlined-dark-hover-background);
				border-color: var(--label-color-outlined-dark-hover-border-color);
				color: var(--label-color-outlined-dark-hover-text);
			}

			&:active {
				background-color: var(--label-color-outlined-dark-active-background);
				border-color: var(--label-color-outlined-dark-active-border-color);
				color: var(--label-color-outlined-dark-active-text);
			}

			&:focus {
				background-color: var(--label-color-outlined-dark-focus-background);
				border-color: var(--label-color-outlined-dark-focus-border-color);
				color: var(--label-color-outlined-dark-focus-text);
				outline-color: var(--label-color-outlined-dark-focus-outline-color);
				outline-style: var(--label-color-outlined-dark-focus-outline-style);
				outline-width: var(--label-color-outlined-dark-focus-outline-width);
			}
		}

		&.Filled {
			background-color: var(--label-color-filled-dark-background);
			color: var(--label-color-filled-dark-text);

			&:hover {
				background-color: var(--label-color-filled-dark-hover-background);
				border-color: var(--label-color-filled-dark-hover-border-color);
				color: var(--label-color-filled-dark-hover-text);
			}

			&:active {
				background-color: var(--label-color-filled-dark-active-background);
				border-color: var(--label-color-filled-dark-active-border-color);
				color: var(--label-color-filled-dark-active-text);
			}

			&:focus {
				background-color: var(--label-color-filled-dark-focus-background);
				border-color: var(--label-color-filled-dark-focus-border-color);
				color: var(--label-color-filled-dark-focus-text);
				outline-color: var(--label-color-filled-dark-focus-outline-color);
				outline-style: var(--label-color-filled-dark-focus-outline-style);
				outline-width: var(--label-color-filled-dark-focus-outline-width);
			}
		}
	}
}
